$base-url: '/assets/img/svg/';

.svg {
  display: inline-block;
  vertical-align: middle;
}

.svg-logo {
  width: 80px;
  min-width: 80px;
  height: 80px;
  //background: url($base-url + 'name.svg') no-repeat;
  background-size: contain;
}
