
$color-white: #ffffff;
$color-black: #000000;
$color-silver: #D7D9E2;
$color-gray: #9D9FB4;
$color-dark-gray: #3E415F;
$color-red: #FF6960;
$color-dark-red: #AC0B1A;


$font-main: 'Ubuntu', sans-serif;


@use "sass:math";

@function rem($px) {
  @return math.div($px, 16) * 1rem;
}
