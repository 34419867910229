@import "vars";
@import "reset";
@import "fonts";
@import "icons";

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  font-family: $font-main;
  font-size: 1rem;
  color: $color-dark-gray;
  min-width: rem(360);
  background: $color-white;

  &.open-menu {
    overflow: hidden;

    .header {
      background: $color-dark-red;
    }
    .nav {
      display: block;
      position: fixed;
      top: rem(60);
      left: 0;
      width: 100%;
      height: 100%;
      padding: 0 rem(16) 0;
      opacity: 1;
      background: $color-dark-red;
      z-index: 2;
      padding-top: rem(20);
      
      &__item {
        text-align: left;
        padding-bottom: rem(20);
        border-bottom: 1px solid $color-red;

        a {
          color: $color-white;
        }

        &.active {
          a {
            color: $color-white;
          }
        }

        & + .nav__item {
          margin-left: 0;
          margin-top: rem(20);
        }
      }
    }
  }
}

.customer {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.main {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

img {
  height: auto;
  max-width: 100%;
}

.h1, h2, h3 {
  font-weight: 700;
  line-height: 1.2em;
  color: $color-dark-gray;
}
.h1 {
  font-size: rem(34);
}
.h2 {
  font-size: rem(34);
}
.h3 {
  font-size: rem(24);
}

.h2 {
  position: relative;
  text-align: center;
  max-width: rem(437);
  margin: 0 auto;

  &:before {
    content: '';
    height: 1.5px;
    background: #D7D9E2;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
  }

  span {
    background: $color-white;
    padding: 0 rem(32);
    display: inline-block;
    position: relative;
  }
}

.link {
  color: blue;
  transition: all, 0.3s, ease;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.t-center {
  text-align: center;
}

.wrapper {
  max-width: 1392px;
  margin: 0 auto;
  padding: 0 rem(24);
}

.header {
  .wrapper {
    padding: 0 rem(24);
    height: rem(84);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .nav {
    display: flex;
    align-items: center;

    &__item {
      text-align: center;

      & + .nav__item {
        margin-left: 60px;
      }

      &.active {
        a {
          color: $color-red;
        }
      }
      a {
        font-weight: 500;
        font-size: rem(14);
        line-height: 1em;
        color: $color-dark-gray;
      }
    }
  }
}

.banner {
  background: #AC0B1A;
  height: 400px;

  .wrapper {
    background: url("../img/banner.png") no-repeat right top;
    background-size: contain;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  &__ttl {
    font-size: rem(54);
    line-height: 1.2em;
    color: $color-white;

    span {
      font-weight: 700;
      text-transform: uppercase;
      font-size: rem(56);
      line-height: 1em;
      letter-spacing: 0.04em;
      display: block;
    }
  }
  &__dsc {
    font-size: rem(16);
    line-height: 1.6em;
    color: $color-white;
    margin-top: rem(24);
    max-width: 540px;
  }

  &--mini {
    height: 182px;

    .wrapper {
      background-image: url("../img/banner-mini.png");
    }
  }
}

.services {
  margin-top: rem(40);

  &__container {
    margin-top: rem(40);
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
  }
  &__box {
    background: #F9F9F9;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: rem(20);

    &--img {
      padding: 0;
    }
    &--rtl {
      flex-direction: row-reverse;

      .services__box-info {
        margin-left: 0;
        margin-right: rem(70);;
      }
    }
  }
  &__box-info {
    margin-left: rem(70);
    max-width: rem(340);

    &__dsc {
      font-size: rem(14);
      line-height: 1.6em;
      color: $color-dark-gray;
      margin-top: rem(16);
    }
  }
  &__dsc {
    font-weight: 500;
    font-size: rem(14);
    line-height: rem(16);
    color: $color-dark-gray;
    margin-top: rem(40);
    text-align: center;
  }
}

.security {
  margin-top: rem(60);

  .h2 {
    max-width: rem(794);
  }
  &__container {
    margin-top: rem(40);
    background: #F9F9F9;

    .wrapper {
      min-height: rem(300);
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      align-content: center;
      justify-items: center;
    }
  }
}

.footer {
  border-top: 1px solid $color-silver;

  .wrapper {
    height: rem(100);
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left {
      display: flex;
      align-items: center;
    }
  }
  &__copyright {
    margin-left: rem(24);
    font-size: rem(14);
    line-height: 1em;
    color: $color-gray;
  }
  &__info {
    display: flex;

    p {
      font-size: rem(14);
      line-height: 1em;
      color: $color-dark-gray;

      & + p {
        margin-left: rem(32);
      }
    }
  }
}

.breadcrumbs {
  display: flex;
  align-items: center;
  margin-top: rem(16);

  &__item {
    font-size: 12px;
    line-height: 100%;
    color: $color-dark-gray;

    & + .breadcrumbs__item {
      position: relative;

      &::before {
        content: '→';
        display: inline-block;
        vertical-align: middle;
        margin: 0 rem(14);
      }
    }

    a {
      color: $color-dark-gray;
    }
    &.active {
      color: $color-red;
    }
  }
}

.services-page,
.about-us,
.documents,
.contacts,
.error404
{
  border-top: 1px solid $color-silver;
  background: #F9F9F9;
  flex-grow: 1;
  padding-bottom: rem(80);

  .h2 {
    margin-top: rem(32);

    span {
      background: #F9F9F9;
    }
  }
  &__container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 17%;
    margin-top: rem(30);
    max-width: 1220px;
    margin-left: auto;
    margin-right: auto;
  }
  &__box {
    .h3 {
      text-align: center;
    }
  }
  &__box-item {

  }
  &__list {
    margin-top: rem(30);
  }
  &__item {
    span, strong {
      font-weight: 700;
      font-size: 18px;
      line-height: rem(28.8);
    }
    span {
      color: $color-red;
    }

    & + .services-page__item {
      margin-top: rem(12);
    }
  }
}

.about-us {
  &__dsc {
    max-width: 928px;
    margin: 0 auto;
    padding-bottom: rem(80);

    p {
      line-height: 1.6em;
      color: $color-dark-gray;

      & + p {
        margin-top: rem(26);
      }
    }
  }
  .h2 + .about-us__dsc {
    margin-top: rem(40);
  }
  &__services-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 9%;
    margin-top: rem(40);
  }
  &__services {
    background: $color-white;
    padding: rem(47) 0 rem(80);


    .h2 {
      span {
        background: $color-white;
      }
    }
    & + .about-us__text-box {
      padding-top: rem(80);

      .about-us__dsc {
        padding-bottom: 0;
      }
    }
  }
  &__services-box {
    display: flex;
    flex-direction: column;
    align-items: center;

    .services__box-info__ttl {
      font-size: rem(20);
      line-height: rem(23);
      margin-top: rem(24);
    }
  }
}

.documents {
  .h2 {
    max-width: rem(928);
  }
  &__main-doc {
    display: flex;
    justify-content: center;
    margin-top: rem(40);
  }
  &__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 9%;
    grid-row-gap: 40px;
    margin-top: rem(40);
  }
  &__box {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__box-dsc {
    font-size: rem(14);
    line-height: 1.6em;
    color: $color-dark-gray;
    margin-top: rem(16);
  }
}

.contacts {
  padding-bottom: 0;

  &__info {
    margin-top: rem(64);
    display: flex;
    flex-direction: column;
    align-items: center;

    strong {
      font-weight: 500;
      font-size: rem(20);
    }

    p + p {
      margin-top: 10px;
    }
  }
  &__map {
    margin-top: rem(93);

    iframe {
      display: block;
    }
  }
}

.error404 {
  background: $color-dark-red;
}

.btn {
  font-weight: 600;
  color: $color-white;
  padding: rem(16) rem(32);
  border: 1px solid $color-white;
  border-radius: rem(30);
  display: inline-block;
}

.error404 {
  padding-bottom: 0;
  display: flex;

  .wrapper {
    padding-top: rem(140);
    padding-bottom: rem(140);
    background: url("../img/404.png") no-repeat right top;
    background-size: cover;
    flex-grow: 1;
  }

  &__ttl {
    font-weight: 700;
    font-size: rem(160);
    line-height: 1em;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: $color-white;
  }
  &__dsc {
    font-size: 16px;
    line-height: 1.6em;
    color: #DDE3EA;
    margin-top: rem(4);
  }
  &__btn {
    margin-top: rem(24);
  }
}

.mobile-nav {
  display: none;
}

.nav-btn {
  width: 30px;
  height: 30px;
  position: relative;
  z-index:2;
  overflow: hidden;

  &.active {
    span {
      background: $color-red;

      &:nth-of-type(1) {
        display: none;
      }
      &:nth-of-type(2) {
        top: 50%;
        transform: translate(-50%, 0%) rotate(45deg);
      }
      &:nth-of-type(3) {
        top: 50%;
        transform: translate(-50%, 0%) rotate(-45deg);       }
    }
  }

  span {
    width: rem(22);
    height: rem(2);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: $color-black;
    transition: all 0.3s;

    &:nth-of-type(2) {
      top: calc(50% - 6px);
    }
    &:nth-of-type(3) {
      top: calc(50% + 6px);
    }
  }
}



//media
@import "media";