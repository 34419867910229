@media screen and (max-width: 1320px) {
  .banner--mini .wrapper {
    background-size: cover;
  }
}

@media screen and (max-width: 1040px) {
  .header {
    .logo {
      img {
        width: rem(93);
      }
    }
    .wrapper {
      height: rem(60);
      padding: 0 rem(18) 0 rem(16);
    }
  }
  .banner {
    &__ttl {
      font-size: rem(32);
    }
    &__dsc {
      font-size: 14px;
      max-width: 260px;
    }
  }
  .services__container {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: auto;
    margin-top: rem(20);

    .services__box {
      flex-direction: column;
      align-items: flex-start;

      &:nth-child(2) {
        grid-row: 1/2;
      }
      &:nth-child(6) {
        grid-row: 5/6;
      }
    }
    .services__box-info {
      margin: rem(12) 0 0;

      .h3 {
        font-size: rem(18);
      }
    }
    .services__box-info__dsc {
      font-size: rem(14);
      margin-top: rem(8);
    }
  }
  .services__dsc {
    margin-top: rem(30);
  }
  .security__container {
    .wrapper {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  .footer .right {
    display: none;
  }
  .footer .left {
    justify-content: space-between;
    width: 100%;
  }
  .footer__logo img {
    width: rem(94);
  }
  .footer .wrapper {
    height: rem(70);
    padding: 0 rem(16);
  }
  .footer__copyright {
    font-size: rem(10);
  }
  .about-us__services {
    padding: rem(8) 0 rem(48);
  }
}

@media screen and (max-width: 780px) {
  .banner:not(.banner--mini) {
    .wrapper {
      background-position: 200% top;
    }
  }
}

@media screen and (max-width: 720px) {
  .header {
    .mobile-nav {
      display: block;
    }
    .nav {
      display: none;
      opacity: 0;
    }
  }
  .banner:not(.banner--mini) {
    .wrapper {
      background: $color-dark-red;
      background: url("../img/banner-mobile.png") no-repeat left bottom/cover;
      align-items: center;
      justify-content: flex-start;
      padding-top: rem(24);
      position: relative;

      &:after {
        content: '';
        background: url("../img/banner-card.png") no-repeat;
        background-size: contain;
        width: 255px;
        height: 160px;
        position: absolute;
        bottom: 5vw;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    .banner__dsc {
      max-width: 100%;
    }
  }
  .services-page__container {
    grid-template-columns: repeat(1, 1fr);
    grid-row-gap: rem(40);
  }

  .about-us__services-container, .documents__container {
    grid-template-columns: repeat(1, 1fr);
    grid-row-gap: rem(20);
  }
  .error404 .wrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  .services-page, .about-us, .documents, .contacts, .error404 {
    padding-bottom: rem(60);
  }
  .about-us__dsc {
    padding-bottom: rem(32);
  }
  .about-us__services + .about-us__text-box {
    padding-top: rem(60);
  }
}